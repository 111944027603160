import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';

import { Head } from '../../components/Head';
import { getToken } from '../../utils';
import { AppContext } from '../../components/Context';
import { STEP_CODE } from '../../constants';
import { useContextHelpers } from '../../utils/hooks';
import { AttachmentMetaDataType, callGetAttachmentMetaData } from '../../apis/documents';
import AttachmentMetaDataView from '../../components/AttachmentMetaDataView';
import LimitedAccess from '../../components/LimitedAccess';
import { Layout } from '../../components/Layout';
import Loader from '../../components/common/Loader';
import { callMojeIdGetPersistedData, parseMojeIdPersistedClientDataResponse } from '../../apis/moje-id';
import IdDetailsForm from './IdDetailsForm';
import { MojeIdGetClientDataResponse } from '../../types/model';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const IdDetails = () => {
    const { t } = useTranslation();
    const { withoutDocumentPhotos, isIdentificationMojeId, existsMojeIdExistence } = useContextHelpers();
    const { initData, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;
    const { ExternalId } = data || {};
    const ParticipantExternalId = data?.Participants[0].ExternalId;
    const token = getToken();
    const [documents, setDocuments] = useState<AttachmentMetaDataType[] | undefined>();
    const [submitting, setSubmitting] = useState(false);
    const [mojeIdConfirmedClientData, setMojeIdConfirmedClientData] = useState<MojeIdGetClientDataResponse | null>(
        null
    );
    const disabledFromMojeId = !!(isIdentificationMojeId && existsMojeIdExistence);

    const [loading, setLoading] = useState(disabledFromMojeId && !mojeIdConfirmedClientData);

    useEffect(() => {
        if (token && ExternalId) {
            callGetAttachmentMetaData({ data: { onlinePolicyExternalId: ExternalId }, token })
                .then((response) => response.data)
                .then((result) => {
                    if (result.DocumentMetadataList) {
                        setDocuments(result.DocumentMetadataList);
                    }
                });
        }
    }, [token, ExternalId]);

    const minStep = withoutDocumentPhotos
        ? STEP_CODE.IDENTITY_VERIFICATION
        : isIdentificationMojeId
        ? STEP_CODE.MOJE_ID
        : STEP_CODE.DOCUMENT_PHOTO;

    useEffect(() => {
        if (isIdentificationMojeId && existsMojeIdExistence) {
            if (ExternalId && ParticipantExternalId && token) {
                callMojeIdGetPersistedData({
                    data: {
                        onlinePolicyExternalId: ExternalId,
                        participantExternalId: ParticipantExternalId,
                    },
                    token,
                })
                    .then((response) => response.data)
                    .then((result) => setMojeIdConfirmedClientData(parseMojeIdPersistedClientDataResponse(result)));
            }
        }
    }, [ExternalId, ParticipantExternalId, token, isIdentificationMojeId, existsMojeIdExistence]);

    useEffect(() => {
        if (loading && mojeIdConfirmedClientData) {
            setLoading(false);
        }
    }, [loading, mojeIdConfirmedClientData]);

    if (loading || documents === undefined) {
        return (
            <Layout continueDisabled>
                <LimitedAccess minStep={minStep}>
                    <Head heading1={t('pages.personalId.title')} heading2={t('pages.personalId.subtitle')} />
                    <Loader />
                </LimitedAccess>
            </Layout>
        );
    }

    return (
        <Layout continueDisabled={submitting}>
            <LimitedAccess minStep={minStep}>
                <Head heading1={t('pages.personalId.title')} heading2={t('pages.personalId.subtitle')} />
                {documents && (
                    <div className="mb-6 lg:grid lg:grid-cols-2 lg:gap-2">
                        {documents
                            .filter((document) =>
                                ['idcard'].includes((document.OnlinePolicyAttachmentTypeCode || '').toLowerCase())
                            )
                            .map((document, index) => (
                                <div key={`document-${document.ExternalId}`} data-test={`documentImage${index + 1}`}>
                                    <AttachmentMetaDataView key={`attachment-${index}`} document={document} />
                                </div>
                            ))}
                    </div>
                )}
                <IdDetailsForm
                    mojeIdClientData={mojeIdConfirmedClientData}
                    disabledFromMojeId={disabledFromMojeId}
                    onSubmitting={setSubmitting}
                />
            </LimitedAccess>
        </Layout>
    );
};

export default IdDetails;
