import cn from 'classnames';
import dayjs from 'dayjs';
import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './common/Button';
import { AppContext } from './Context';
import metadata from '../metadata.json';
import { isDevelopment } from '../utils';
import { Tooltip } from './common/Tooltip';

interface FooterProps {
    hideContinue: boolean;
    continueText?: string;
    continueTooltip?: ReactNode;
    continueDisabled?: boolean;
    continueCallback?: () => void;
    footerContent?: ReactNode;
    footerClassName?: string;
    className?: string;
}

export const Footer = ({
    hideContinue,
    continueText,
    continueTooltip,
    continueDisabled,
    continueCallback,
    footerContent,
    footerClassName,
    className,
}: FooterProps) => {
    const ctx = useContext(AppContext);
    const { t } = useTranslation();

    // TODO: pokusit se odstranit použití ctx?.currentFormik?.submitForm()
    const handleContinueOnClick = () => {
        continueCallback ? continueCallback() : ctx?.currentFormik?.submitForm();
    };

    const continueButton = (
        <Button onClick={handleContinueOnClick} isDisabled={continueDisabled} innerSpan data-test="continueButton">
            {continueText ?? t('common.continueAndSave')}
        </Button>
    );

    return (
        <footer
            className={cn(
                className,
                'fixed bottom-0 left-0 z-50 flex min-h-[4.375rem] w-full items-center justify-center bg-white px-4 py-5 shadow-top md:h-[6.125rem] md:px-8 md:py-0'
            )}
        >
            <div className={cn('flex w-full max-w-md2 items-center justify-end gap-x-4', footerClassName)}>
                {footerContent}
                {!hideContinue && (
                    <>
                        {continueTooltip ? (
                            <Tooltip content={continueTooltip} data-test="continueTooltip">
                                {continueButton}
                            </Tooltip>
                        ) : (
                            <div>{continueButton}</div>
                        )}
                    </>
                )}
            </div>

            {!isDevelopment() && process.env.REACT_APP_ENV && (
                <div className="absolute bottom-1 left-1 text-[9px]">
                    <>
                        ({process.env.REACT_APP_ENV})
                        {window &&
                        window.location.origin &&
                        (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
                            ? window.location.hostname
                            : dayjs(metadata.lastBuildDtt).format('DD.MM.YYYY HH:mm:ss')}
                    </>
                </div>
            )}
        </footer>
    );
};
