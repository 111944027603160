import { Field, Form, FormikProps, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useContext, useRef } from 'react';

import { Card } from '../../components/common/Card';
import { Grid } from '../../components/Grid';
import { Select } from '../../components/common/Select';
import { Input } from '../../components/common/Input';
import { CustomFormik } from '../../components/CustomFormik';
import {
    addDate,
    getHigherStep,
    getIdCardSelectList,
    getOwner,
    getSelectValueByCode,
    nowDate,
    subtractDate,
} from '../../utils';
import { config } from '../../config';
import { AppContext } from '../../components/Context';
import {
    MojeIdGetClientDataResponse,
    ParticipantPersonProps,
    PersonalIdentificationFullProps,
} from '../../types/model';
import { ROUTE, STEP_CODE } from '../../constants';
import { useAppNavigate } from '../../utils/hooks';
import { DetectFormikChanged } from '../../components/common/DetectFormikChanged';
import DateInput from '../../components/common/DateInput';

interface IdDetailsFormProps {
    mojeIdClientData: MojeIdGetClientDataResponse | null;
    disabledFromMojeId: boolean;
    onSubmitting: (submitting: boolean) => void;
}

const IdDetailsForm = ({ mojeIdClientData, disabledFromMojeId, onSubmitting }: IdDetailsFormProps) => {
    const { t } = useTranslation();
    const { navigateTo } = useAppNavigate();
    const now = nowDate();
    const { initData, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;
    const idList = getIdCardSelectList(initData);
    const policyOwnerId = data ? getOwner(data)?.ParticipantExternalId : null;
    const ownerParticipant =
        (data?.Participants?.find(
            (p) => p.ExternalId === policyOwnerId
        ) as ParticipantPersonProps<PersonalIdentificationFullProps>) ?? null;
    const otherParticipants = data?.Participants?.filter((p) => p.ExternalId !== policyOwnerId);
    const myFormikRef = useRef<FormikProps<any>>(null);

    const formSchema = Yup.object().shape({
        documentType: Yup.object().required(),
        idNumber: Yup.string().required(),
        issueDate: Yup.date().required().max(now).min(subtractDate(config.MAX_DOCUMENT_AGE, 'years')),
        expirationDate: Yup.date().required().min(now).max(addDate(config.MAX_DOCUMENT_AGE, 'years')),
        issuedBy: Yup.string().required().min(2),
    });

    const mojeIdCard =
        mojeIdClientData?.IdCards && mojeIdClientData.IdCards.length > 0 ? mojeIdClientData.IdCards[0] : null;

    const initialValues = {
        idNumber: ownerParticipant?.Identification?.IdCard?.IdCardNo ?? mojeIdCard?.IdCardNo ?? '',
        issueDate: ownerParticipant?.Identification?.IdCard?.IssuedDt ?? mojeIdCard?.IssuedDt ?? '',
        expirationDate: ownerParticipant?.Identification?.IdCard?.ExpiryDt ?? mojeIdCard?.ExpiryDt ?? '',
        issuedBy: ownerParticipant?.Identification?.IdCard?.Issuer ?? mojeIdCard?.Issuer ?? '',
        documentType:
            (idList &&
                getSelectValueByCode(idList, ownerParticipant?.Identification?.IdCard?.Kind ?? mojeIdCard?.Kind)) ??
            (idList && idList[0]) ??
            '',
    };

    return (
        <CustomFormik
            initialValues={initialValues}
            onSubmit={async (v) => {
                if (data) {
                    onSubmitting(true);
                    const newModel = {
                        ...data,
                        Settings: {
                            ...data.Settings,
                            CurrentStepCode: getHigherStep(data.Settings.CurrentStepCode, STEP_CODE.ID_DETAILS),
                        },
                    };

                    ctx.setCurrentModel(newModel);
                    await ctx.saveCurrentModel(newModel);
                    onSubmitting(false);
                }
                navigateTo(ROUTE.PERSONAL_DATA);
            }}
            validationSchema={formSchema}
            customRender
            passedRef={myFormikRef}
        >
            <Form>
                <DetectFormikChanged
                    onChange={(v: FormikValues) => {
                        if (ownerParticipant && ownerParticipant?.Identification && v && data) {
                            const updatedParticipant = ownerParticipant;

                            updatedParticipant.Identification.IdCard = {
                                ...updatedParticipant.Identification.IdCard,
                                ExpiryDt: v?.expirationDate || null,
                                IdCardNo: v?.idNumber || null,
                                IssuedDt: v?.issueDate || null,
                                Issuer: v?.issuedBy || null,
                                Kind: v?.documentType.value,
                            };

                            ctx.setCurrentModel({
                                ...data,
                                Participants: [updatedParticipant, ...(otherParticipants || [])],
                            });
                        }
                    }}
                />
                <Card data-test="idDetailsCard">
                    <Grid>
                        <Field
                            name="documentType"
                            component={Select}
                            label={t('common.formFields.documentType')}
                            isDisabled={disabledFromMojeId}
                            isCenter
                            isSearchable={false}
                            options={idList}
                            data-test="documentType"
                        />
                        <Field
                            name="idNumber"
                            label={t('common.formFields.identificatorNumber')}
                            isDisabled={disabledFromMojeId}
                            isCenter
                            component={Input}
                            maxLength={50}
                            data-test="idNumber"
                        />
                        <Field
                            name="issueDate"
                            label={t('common.formFields.issueDate')}
                            isDisabled={disabledFromMojeId}
                            isCenter
                            component={DateInput}
                            minDate={new Date(subtractDate(config.MAX_DOCUMENT_AGE, 'years'))}
                            maxDate={new Date(nowDate())}
                            data-test="issueDate"
                        />
                        <Field
                            name="expirationDate"
                            label={t('common.formFields.expirationDate')}
                            isDisabled={disabledFromMojeId}
                            isCenter
                            component={DateInput}
                            minDate={new Date(addDate(1, 'days'))}
                            maxDate={new Date(addDate(config.MAX_DOCUMENT_AGE, 'years'))}
                            data-test="expirationDate"
                        />
                        <Field
                            name="issuedBy"
                            label={t('common.formFields.issuedBy')}
                            isDisabled={disabledFromMojeId}
                            isCenter
                            component={Input}
                            data-test="issuedBy"
                        />
                    </Grid>
                </Card>
            </Form>
        </CustomFormik>
    );
};

export default IdDetailsForm;
