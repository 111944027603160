import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallCheckMojeidDataExistenceProps {
    data: {
        onlinePolicyExternalId: string;
    };
    token: string;
}

export interface CallCheckMojeidDataExistenceResponse {
    Exists: boolean;
    HasConfirmedData: boolean;
}

export const callCheckMojeidDataExistence = ({
    data,
    token,
}: CallCheckMojeidDataExistenceProps): Promise<AxiosResponse<CallCheckMojeidDataExistenceResponse>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}support/check-mojeid-data-existence`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
