import React, { ReactNode } from 'react';
import cn from 'classnames';
import { PopupType } from '../../types';
import { Card } from './Card';

interface PopupWrapperProps {
    popupType: PopupType;
    color?: 'yellow' | 'lightPurple';
    className?: string;
    children: ReactNode;
}

const PopupWrapper = ({ popupType, color = 'yellow', className, children }: PopupWrapperProps) => {
    const cls = cn(
        'fixed left-1/2 top-1/2 w-[calc(100%-2rem)] -translate-x-1/2 -translate-y-1/2 flex flex-col',
        className
    );

    const background = <div className="fixed bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-90" />;

    if (popupType === 'mojeid') {
        return (
            <>
                <Card
                    className={cn(cls, 'z-60 max-h-[85%] max-w-md2 overflow-auto md:px-16 md:py-12')}
                    data-test={`popup-${popupType.replace(/-([a-z])/g, (g) => g[1].toUpperCase())}`}
                >
                    <div className="relative">{children}</div>
                </Card>
                {background}
            </>
        );
    }

    return (
        <>
            <div
                className={cn(
                    cls,
                    `z-60 max-w-sm rounded-sm bg-${color || 'yellow'} px-4 py-6 text-darkPurple shadow-popup`
                )}
                data-test={`popup-${popupType.replace(/-([a-z])/g, (g) => g[1].toUpperCase())}`}
            >
                {children}
            </div>
            {background}
        </>
    );
};

export default PopupWrapper;
