import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TFunction } from 'i18next';

import { AppContext } from './Context';
import { PopupType } from '../types';
import {
    MissingDataContent,
    LostPasswordContent,
    PepContent,
    InsurablePersonContent,
    LostDataContent,
    MojeIdContent,
    WaitingPaymentContent,
    ReceivedPaymentContent,
    ChangePasswordDoneContent,
    ResetPasswordDoneContent,
    ResetPasswordErrorContent,
    DistributorErrorContent,
    LimitedAccessFromBasicInfoContent,
    ManualUploadResetContent,
    LimitedAccessToPublicContent,
    DraftCannotContinueContent,
    NavigationButtonsDisabledContent,
    InitPasswordDoneContent,
    SmsSendedContent,
} from './popup/index';
import PopupWrapper from './common/PopupWrapper';
import ReportBugPopup from './popup/ReportBugPopup';

export const Popup = (): ReactElement | null => {
    const ctx = useContext(AppContext);
    const { popupType } = ctx;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const renderContent = (t: TFunction<'translation', undefined, 'translation'>, type?: PopupType) =>
        type === 'missing-data' ? (
            MissingDataContent(t, ctx)
        ) : type === 'lost-password' ? (
            LostPasswordContent(t, ctx)
        ) : type === 'pep' ? (
            PepContent(t, ctx)
        ) : type === 'insurable' ? (
            InsurablePersonContent(t, ctx, navigate)
        ) : type === 'lost-data-warning' ? (
            LostDataContent(t, ctx)
        ) : type === 'mojeid' ? (
            MojeIdContent(t, ctx)
        ) : type === 'waiting-payment' ? (
            WaitingPaymentContent(t, ctx)
        ) : type === 'received-payment' ? (
            ReceivedPaymentContent(t, ctx)
        ) : type === 'change-password-done' ? (
            ChangePasswordDoneContent(t, ctx)
        ) : type === 'init-password-done' ? (
            InitPasswordDoneContent(t, ctx)
        ) : type === 'reset-password-done' ? (
            ResetPasswordDoneContent(t, ctx)
        ) : type === 'reset-password-error' ? (
            ResetPasswordErrorContent(t, ctx)
        ) : type === 'distributor-error' ? (
            DistributorErrorContent(t, ctx)
        ) : type === 'limited-access-from-basic-info' ? (
            LimitedAccessFromBasicInfoContent(t, ctx)
        ) : type === 'limited-access-to-public' ? (
            LimitedAccessToPublicContent(t, ctx)
        ) : type === 'manual-upload-reset' ? (
            ManualUploadResetContent(t, ctx)
        ) : type === 'draft-cannot-continue' ? (
            DraftCannotContinueContent(t, ctx)
        ) : type === 'navigation-buttons-disabled' ? (
            NavigationButtonsDisabledContent(t, ctx)
        ) : type === 'success-sms-sended' ? (
            SmsSendedContent(t, ctx)
        ) : (
            <></>
        );

    switch (popupType) {
        case 'report-bug':
            return <ReportBugPopup />;
        default:
            if (popupType) {
                return <PopupWrapper popupType={popupType}>{renderContent(t, popupType)}</PopupWrapper>;
            }

            return null;
    }
};
