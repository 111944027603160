import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallMojeIdGenerateCodeProps {
    data: {
        onlinePolicyExternalId: string;
    };
}

export const callMojeIdGenerateCode = ({ data }: CallMojeIdGenerateCodeProps): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}moje-id/generate-moje-id-code`,
        data,
    });
