import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomFormik } from '../../components/CustomFormik';
import { Head } from '../../components/Head';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { List } from '../../components/common/List';
import { AppContext } from '../../components/Context';
import { Icon } from '../../components/Icon';
import { ROUTE, STEP_CODE } from '../../constants';
import { callCheckMojeidDataExistence } from '../../apis/support';
import { useAppNavigate, useContextHelpers } from '../../utils/hooks';
import Alert from '../../components/Alert';
import { Layout } from '../../components/Layout';
import FooterContent from './FooterContent';
import { ModelProps } from '../../types/model';
import { getHigherStep } from '../../utils';

export const MojeId = () => {
    const { t } = useTranslation();
    const pageTranslation: any = t('pages.mojeId', { returnObjects: true });
    const [errorNotAllowContinue, setErrorNotAllowContinue] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const { setMojeIdExistence } = useContext(AppContext);
    const { isF2F, isRemote } = useContextHelpers();
    const { navigateTo } = useAppNavigate();
    const token = localStorage.getItem('token');

    const ctx = useContext(AppContext);
    const data = ctx.currentModel;

    const continueNext = (currentStep: string, nextRoute: string) => {
        if (data) {
            setContinueDisabled(true);
            const newData = {
                ...data,
                Settings: {
                    ...data.Settings,
                    CurrentStepCode: getHigherStep(data.Settings?.CurrentStepCode, currentStep),
                },
            } as ModelProps;

            ctx.setCurrentModel(newData);
            ctx.saveCurrentModel(newData).then(() => {
                setContinueDisabled(false);
                navigateTo(nextRoute);
            });
        }
    };

    return (
        <Layout
            continueText={isF2F ? t<string>('common.clientFinishedMojeId') : undefined}
            continueDisabled={continueDisabled}
            footerContent={<FooterContent onSetContinueDisabled={setContinueDisabled} />}
        >
            <Head heading1={t('pages.mojeId.title')} heading2={t('pages.mojeId.subtitle')} />

            <CustomFormik
                initialValues={{}}
                onSubmit={(v: any) => {
                    if (token && data) {
                        setContinueDisabled(true);
                        setErrorNotAllowContinue(false);
                        callCheckMojeidDataExistence({
                            data: {
                                onlinePolicyExternalId: data.ExternalId,
                            },
                            token,
                        })
                            .then((response) => response.data)
                            .then((result) => {
                                // uložení do storu
                                setMojeIdExistence(result);
                                // F2F
                                if (isF2F) {
                                    if (result.Exists && result.HasConfirmedData) {
                                        continueNext(STEP_CODE.MOJE_ID, ROUTE.ID_DETAILS);
                                    } else {
                                        setErrorNotAllowContinue(true);
                                    }
                                } else if (isRemote) {
                                    // Remote
                                    if (result.Exists && result.HasConfirmedData) {
                                        continueNext(STEP_CODE.MOJE_ID, ROUTE.ID_DETAILS);
                                    } else {
                                        continueNext(STEP_CODE.PERSONAL_DATA, ROUTE.BENEFICIARIES);
                                    }
                                }
                            })
                            .catch(() => setErrorNotAllowContinue(true))
                            .finally(() => {
                                setContinueDisabled(false);
                            });
                    }
                }}
            >
                <div className="flex flex-col">
                    <Card data-test="mojeIdCard">
                        <div className="inline-flex items-center justify-center gap-x-6">
                            <Title tag="h2" size="md" fontWeight="medium" className="mx-0 mb-8" data-test="mojeIdTitle">
                                {t('pages.mojeId.card.title')}
                                <Icon
                                    name="question"
                                    className="ml-10 cursor-pointer"
                                    onClick={() => {
                                        ctx.showPopup('mojeid');
                                    }}
                                    data-test="mojeIdIcon"
                                />
                            </Title>
                        </div>

                        <List type="ordered" items={pageTranslation.card.list} data-test="mojeIdList" />
                        <p className="mt-6 px-10 text-center" data-test="mojeIdSuccess">
                            {pageTranslation.card.successText}
                        </p>
                    </Card>

                    {errorNotAllowContinue && (
                        <Alert
                            className="mb-4 mt-8"
                            description={t<string>('pages.mojeId.notAllowContinue')}
                            data-test="mojeIdAlert"
                        />
                    )}
                </div>
            </CustomFormik>
        </Layout>
    );
};
