import { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useAppNavigate, useContextHelpers, useOutsideClickChecker, useProcessRoutes } from '../../utils/hooks';
import { RouteProps } from '../../routes';
import { ROUTE } from '../../constants';
import { Icon } from '../Icon';

interface ProgressItemProps {
    name: string;
    index: number;
    isDisabled: boolean;
    currentPageIndex: number;
}

interface ProgressProps {
    className?: string;
}

const ProgressItem = ({ name, index, isDisabled, currentPageIndex }: ProgressItemProps) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div
                className={cn(
                    'flex h-[1.25rem] w-[1.25rem] shrink-0 items-center justify-center rounded-full border-1 border-purple text-center text-white',
                    index < currentPageIndex ? 'bg-purple [&:after]:text-[10px] [&:after]:content-["✓"]' : 'bg-white'
                )}
            />
            <span className={cn(isDisabled && 'font-regular text-gray')}>{t(`pageNames.${name}`)}</span>
        </Fragment>
    );
};

export const Progress = ({ className }: ProgressProps) => {
    const location = useLocation();
    const [active, setActive] = useState(false);
    const { navigateTo } = useAppNavigate();
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const { maxStepCode } = useContextHelpers();
    const { processRoutes, getRouteIndexByPath, getNextRouteIndex } = useProcessRoutes();

    const maxPosition = useMemo(() => getNextRouteIndex(maxStepCode), [maxStepCode, getNextRouteIndex]);

    const position = useMemo(() => getRouteIndexByPath(location.pathname), [location.pathname, getRouteIndexByPath]);

    const finallyPosition = Math.max(position, maxPosition);

    let currentProgress = Math.floor(100 / (processRoutes.length / (finallyPosition + 1)));
    if (currentProgress > 100) {
        currentProgress = 100;
    }

    useOutsideClickChecker(wrapperRef, () => setActive(false));

    const onClickItem = (item: RouteProps) => {
        navigateTo(item.path);
        setActive(false);
    };

    const isFinished = useMemo(
        () =>
            !![ROUTE.SUMMARY_DOCUMENTS, ROUTE.SUMMARY_FAIL, ROUTE.FINISH, ROUTE.FINISH_REMOTE].find((route) =>
                matchPath(location.pathname, route)
            ),
        [location.pathname]
    );

    const disableMenu = useCallback(
        (menuItem: RouteProps) => {
            if (isFinished) {
                return ![
                    ROUTE.MAIN_MENU,
                    ROUTE.DASHBOARD_MAIN,
                    ROUTE.SUMMARY_DOCUMENTS,
                    ROUTE.SUMMARY_FAIL,
                    ROUTE.FINISH,
                    ROUTE.FINISH_REMOTE,
                ].find((route) => matchPath(menuItem.path, route));
            }

            return false;
        },
        [isFinished]
    );

    return (
        <div
            ref={wrapperRef}
            className={cn(className, 'relative w-[8.125rem] sm:w-[10rem] md:w-[15rem]')}
            data-test="progress"
        >
            <div
                onClick={() => setActive(!active)}
                className="relative flex h-8 w-full cursor-pointer items-center justify-center overflow-hidden rounded-full border-2 border-purple text-center text-darkPurple md:h-[2.25rem]"
            >
                <span className="font-medium">{currentProgress} %</span>
                <div className="absolute left-0 top-0 h-full bg-purple10" style={{ width: `${currentProgress}%` }} />
                <Icon
                    name="chevron-up"
                    className={cn(
                        'absolute right-4 top-1/2 -translate-y-1/2 transition-transform',
                        active ? 'rotate-0' : 'rotate-180'
                    )}
                />
            </div>

            {processRoutes.length > 0 && (
                <AnimatePresence>
                    {active && (
                        <motion.div
                            initial={{
                                height: 0,
                                opacity: 0,
                            }}
                            animate={{
                                height: 'auto',
                                opacity: 1,
                            }}
                            exit={{
                                height: 0,
                                opacity: 0,
                            }}
                            className="absolute z-100 mt-1 rounded-lg bg-darkPurple p-4 pr-0 text-xs font-medium text-white md:text-sm mdmax:left-1/2 mdmax:w-[15rem] mdmax:-translate-x-1/2"
                            data-test="progressBar"
                        >
                            <div className="scrollbar flex max-h-[80vh] w-full flex-col gap-x-4 gap-y-2 overflow-y-auto pr-4">
                                {processRoutes.map((item: RouteProps, i: number) => {
                                    const cls = 'flex flex-row gap-x-4';

                                    return i > finallyPosition ? (
                                        <div
                                            key={item.path}
                                            className={cls}
                                            onClick={() => setActive(false)}
                                            data-test="progressBarScreen"
                                        >
                                            <ProgressItem
                                                name={item.title}
                                                index={i}
                                                isDisabled={true}
                                                currentPageIndex={finallyPosition}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            key={item.path}
                                            className={cn(cls, !disableMenu(item) && 'cursor-pointer')}
                                            onClick={() => !disableMenu(item) && onClickItem(item)}
                                            data-test="progressBarScreen"
                                        >
                                            <ProgressItem
                                                name={item.title}
                                                index={i}
                                                isDisabled={disableMenu(item)}
                                                currentPageIndex={finallyPosition}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            )}
        </div>
    );
};
