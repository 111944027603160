import { LocaleProps } from '../types';

export const PL_CURRENCY = 'PLN';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const HUMAN_DATE_FORMAT = 'D. M. YYYY';

export const DEFAULT_PHONEPREFIX = '+48';
export const DEFAULT_PHONEPREFIX_ID = '177';
export const OCCUPATION_QUESTION_CODE = 'PL_OCCUPATION';

export const SMS_CODE_LENGTH = 6;
export const INIT_PASSWORD_SMS_CODE_LENGTH = 8;

export const SUPPORTED_UPLOAD_FILE_TYPES = '.jpg,.jpeg,.png,.gif,.heic,.heif,application/pdf';

export const LOCALES: Array<LocaleProps> = [
    { code: 'pl', title: 'Polski' },
    { code: 'en', title: 'English' },
];

export const REGEX = {
    POLISH_IBAN_REGEX: '^PL[0-9]{26}',
    POLISH_POST_REGEX: '^[0-9]{2}-[0-9]{3}',
    CZECH_CELLPHONE_REGEX: '^(6|7)\\d{8}$',
    POLISH_ID_REGEX: '^[a-zA-Z]{3}\\d{6}$', // ZZC301730 [a-zA-Z]{3}\\d{6}
    _POLISH_PHONE_REGEX: '^(?:1[2-8]|2[2-69]|3[2-49]|4[1-8]|5[0-9]|6[0-35-9]|[7-8][1-9]|9[145])\\d{7}',
    POLISH_PHONE_REGEX: '^[4-9]{1}[0-9]{8}$',
    BASIC_PHONE_REGEX: '^[0-9]{9}',
    POLISH_PESEL_REGEX: '^[0-9]{2}([02468]1|[13579][012])(0[1-9]|1[0-9]|2[0-9]|3[01])[0-9]{5}$',
    POLISH_BANK_NUMBER: '^(PL)?\\d{26}$', // PL01234567890123456789123456
    POLISH_PASSPORT_NUMBER: '^(ZF|ZE|ZD|ZC|PZ|DD|CC|YA)[0-9]{7}$',
    EMAIL_REGEX:
        '^(?:[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$',
};

export const TEST_API_URL = 'https://sp-test-simagepl-ne-app01.azurewebsites.net';

export const API_PATH = 'api/online-poland/agent-web/';

export const IDENTIFICATION_FULL_TYPE =
    'Eden.Paris.OnlineManagerService.SharedModels.PersonalIdentificationModel.PersonalIdentificationFull, Eden.Paris.OnlineManagerService.SharedModels';
export const IDENTIFICATION_LINK_TYPE =
    'Eden.Paris.OnlineManagerService.SharedModels.PersonalIdentificationModel.PersonalIdentificationLink, Eden.Paris.OnlineManagerService.SharedModels';

export enum ROUTE {
    LOGIN = '/login',
    COVER_ADJUST = '/cover-adjust',
    RESET_PASSWORD = '/reset-password',
    CHANGE_PASSWORD = '/change-password',
    INIT_PASSWORD = '/init-password',
    DESIGN_SYSTEM = '/design-system',
    MAIN_MENU = '/main-menu',
    DASHBOARD = '/dashboard',
    DASHBOARD_MAIN = '/dashboard/main',
    DASHBOARD_MY_POLICY = '/dashboard/my-policy',
    DASHBOARD_AGENCY_POLICY = '/dashboard/agency-policy',
    DASHBOARD_DRAFTS = '/dashboard/drafts',
    DASHBOARD_MY_CLIENTS = '/dashboard/my-clients',
    DASHBOARD_AGENCY_CLIENTS = '/dashboard/agency-clients',
    DASHBOARD_KNOWLEDGE = '/dashboard/knowledge/:section',
    DASHBOARD_CONTACTS = '/dashboard/contacts',
    CONCLUSION = '/conclusion',
    PACKAGE_CHOICE = '/package-choice',
    SUMMARY_DOCUMENTS = '/summary-documents',
    SUMMARY_FAIL = '/summary-fail',
    FINISH = '/finish',
    FINISH_REMOTE = '/finish-remote',
    CONFIRMATION_DATA = '/confirmation-data',
    CLIENT_MOJE_ID = '/client-moje-id',
    CLIENT_PERSONAL_DATA = '/client-personal-data',
    CONTACTS_CHECK = '/contacts-check',
    CONTACTS_CHECK_APPROVE = '/contacts-check-approve',
    IDENTITY_VERIFICATION = '/identity-verification',
    CLIENT_VERIFY_IDENTITY = '/client-verify-identity/:identityId',
    CLIENT_VERIFY_SNAP = '/client-verify-snap/:identityId',
    CLIENT_VERIFY_IDENTITY_FINAL = '/client-verify-identity-final/:identityId',
    CLIENT_IDENTIFICATION_FINAL = '/client-identification-final',
    ID_DETAILS = '/id-details',
    PERSONAL_DATA = '/personal-data',
    DOCUMENT_PHOTO = '/document-photo',
    QUESTIONNAIRE = '/questionnaire',
    BENEFICIARIES = '/beneficiaries',
    SUMMARY = '/summary',
    SUMMARY_PREPARING = '/summary-preparing',
    MOJE_ID = '/moje-id',
    BASIC_DATA = '/basic-info',
    INVALID_LINK = '/invalid-link',
    UNACCESSIBLE = '/unaccessible',
    ERROR_500 = '/500',
    ERROR_404 = '/404',
    CONTINUE_DRAFT = '/continue-draft',
    AUTOPAY_CONFIRMATION = '/autopay-confirmation',
    AUTOPAY_PAYMENT_METHOD = '/online-payment/:externalId',
    AUTOPAY_POLICY_PAID = '/autopay-policy-paid',
    MOJE_ID_AUTH = '/moje-id-auth',
    // formulář škodní události
    DAMAGE_EVENT = '/damage-event',
}

export enum STEP_CODE {
    MAIN_MENU = 'MAIN_MENU',
    CONCLUSION = 'CONCLUSION',
    QUESTIONNAIRE = 'QUESTIONNAIRE',
    PACKAGE_CHOICE = 'PACKAGE_CHOICE',
    BASIC_DATA = 'BASIC_DATA',
    COVER_ADJUST = 'COVER_ADJUST',
    CONTACTS_CHECK = 'CONTACTS_CHECK',
    IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION',
    DOCUMENT_PHOTO = 'DOCUMENT_PHOTO',
    MOJE_ID = 'MOJE_ID',
    ID_DETAILS = 'ID_DETAILS',
    PERSONAL_DATA = 'PERSONAL_DATA',
    BENEFICIARIES = 'BENEFICIARIES',
    SUMMARY = 'SUMMARY',
    SUMMARY_PREPARING = 'SUMMARY_PREPARING',
    SUMMARY_DOCUMENTS = 'SUMMARY_DOCUMENTS',
    FINISH_REMOTE = 'FINISH_REMOTE',
}

export enum AGENT_PORTAL_RIGHTS {
    POLICY_LIST = 'AGENT_PORTAL_POLICY_LIST',
    POLICY_LIST_AGENCY = 'AGENT_PORTAL_POLICY_LIST_AGENCY',
    POLICY_DETAIL = 'AGENT_PORTAL_POLICY_DETAIL',
    POLICY_DETAIL_AGENCY = 'AGENT_PORTAL_POLICY_DETAIL_AGENCY',
    CLIENT_LIST = 'AGENT_PORTAL_CLIENT_LIST',
    CLIENT_LIST_AGENCY = 'AGENT_PORTAL_CLIENT_LIST_AGENCY',
    CLIENT_DETAIL = 'AGENT_PORTAL_CLIENT_DETAIL',
    CLIENT_DETAIL_AGENCY = 'AGENT_PORTAL_CLIENT_DETAIL_AGENCY',
    DRAFT_LIST = 'AGENT_PORTAL_DRAFT_LIST',
    CREATE_POLICY = 'AGENT_PORTAL_CREATE_POLICY',
    CHANGE_POLICY = 'AGENT_PORTAL_CHANGE_POLICY',
    NOTIFIED_EVENT_LIST = 'AGENT_PORTAL_NOTIFIED_EVENT_LIST',
    NOTIFIED_EVENT_DETAIL = 'AGENT_PORTAL_NOTIFIED_EVENT_DETAIL',
    CREATE_NOTIFIED_EVENT = 'AGENT_PORTAL_CREATE_NOTIFIED_EVENT',
}
