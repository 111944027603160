import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FormikProps } from 'formik';

import { Head } from '../../components/Head';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { AppContext } from '../../components/Context';
import { callValidateFinish, callValidateOnlinePolicyPlSanction } from '../../apis/validations';
import { AgentPersonalDataType, ValidationResultType } from '../../types';
import GlobalError from '../../components/GlobalError';
import { getHigherStep, getOwner, parseValidationErrors } from '../../utils';
import { CustomFormik } from '../../components/CustomFormik';
import { Switch } from '../../components/common/Switch';
import { UnorderedList } from '../../components/UnorderedList';
import { callEvaluate } from '../../apis/calculations';
import { IDENTIFICATION_LINK_TYPE, PL_CURRENCY, ROUTE, STEP_CODE } from '../../constants';
import { useAppNavigate, useContextHelpers } from '../../utils/hooks';
import { Help } from '../../components/common/Help';
import { callGetAgentPersonalData } from '../../apis/support/getAgentPersonalData';
import LimitedAccess from '../../components/LimitedAccess';
import Loader from '../../components/common/Loader';
import { Layout } from '../../components/Layout';
import TableSummaryInformation from './TableSummaryInformation';
import TableCoverage from './TableCoverage';
import {
    ModelProps,
    ParticipantPersonProps,
    PersonalIdentificationFullProps,
    PersonalIdentificationLinkProps,
} from '../../types/model';
import { IdentityConfirmatorType } from '../../types/enums/IdentityConfirmatorType';

const Summary = () => {
    const { t } = useTranslation();
    const { userData, summaryErrors, setSummaryErrors, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;
    const { isRemote, isIdentificationMojeId, existsMojeIdExistence } = useContextHelpers();
    const { navigateTo } = useAppNavigate();
    const token = localStorage.getItem('token');
    const formikRef = useRef<FormikProps<any>>(null);
    const [loading, setLoading] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const [errorSanctionRegion, setErrorSanctionRegion] = useState(false);
    const [agentPersonalData, setAgentPersonalData] = useState<AgentPersonalDataType | null>(null);

    const ownerId = data ? getOwner(data)?.ParticipantExternalId : null;
    const ownerParticipant = data?.Participants?.find((p) => p.ExternalId === ownerId) as ParticipantPersonProps<
        PersonalIdentificationFullProps | PersonalIdentificationLinkProps
    >;
    const otherParticipants = data?.Participants.filter((p) => p && p.ExternalId !== ownerId);

    useEffect(() => {
        if (token && userData) {
            callGetAgentPersonalData({ data: { agentIdentityLoginExternalId: userData.extId }, token })
                .then((response) => response.data)
                .then((result) => {
                    setAgentPersonalData(result.AgentPersonalDataPlDto);
                });
        }
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        setSummaryErrors([]);
    }, [setSummaryErrors]);

    const continueNextStep = async (newModel: ModelProps) => {
        newModel.Settings.CurrentStepCode = getHigherStep(newModel.Settings.CurrentStepCode, STEP_CODE.SUMMARY);

        ctx.setCurrentModel(newModel);
        await ctx.saveCurrentModel(newModel);

        navigateTo(ROUTE.SUMMARY_PREPARING);
    };

    return (
        <Layout
            continueText={t<string>('common.completeContract')}
            continueDisabled={loading || continueDisabled}
            continueTooltip={errorSanctionRegion && t('pages.personalContactInformation.tooltipIsFromSanctionRegion')}
            footerContent={
                <div className="ml-auto mr-4 flex items-center font-medium">
                    <Help
                        text={`${t('common.proposalContinueInfoStart')} ${t('common.completeContract')} ${t(
                            'common.proposalContinueInfoEnd'
                        )}`}
                        data-test="proposalContinueInfo"
                    />
                </div>
            }
            continueCallback={async () => {
                const userAgreed = formikRef.current?.values.agreement;
                setErrorSanctionRegion(false);

                if (!userAgreed) {
                    ctx.showPopup('distributor-error');
                } else {
                    if (token) {
                        setLoading(true);
                        setContinueDisabled(true);

                        // remove + mojeid + data z mojeid neexistují
                        if (data && isRemote && isIdentificationMojeId && !existsMojeIdExistence) {
                            ownerParticipant.Identification = {
                                $type: IDENTIFICATION_LINK_TYPE,
                                BirthDt: ownerParticipant.Identification.BirthDt,
                                CurrentAge: ownerParticipant.Identification.CurrentAge,
                                Sex: ownerParticipant.Identification.Sex,
                            };
                            data.Participants = [ownerParticipant, ...(otherParticipants ?? [])];
                        }

                        // remove + mojeid
                        if (data && isRemote && isIdentificationMojeId) {
                            data.PolicyOwners[0].IdentityConfirmation.IdentityConfirmatorType =
                                IdentityConfirmatorType.Client;
                        }

                        // evaluate
                        callEvaluate({ data: { jsonOnlineModel: JSON.stringify(data) }, token })
                            .then((response) => response.data)
                            .then(async (result) => {
                                // uložení modelu do contextu
                                const newModel = { ...result };
                                ctx.setCurrentModel(newModel);
                                await ctx.saveCurrentModel(newModel);

                                // validate online policy pl sanction
                                let sanction = null;
                                try {
                                    sanction = await callValidateOnlinePolicyPlSanction({
                                        data: {
                                            onlinePolicyPlJson: JSON.stringify(newModel),
                                        },
                                        token,
                                    });
                                } catch {}

                                // je v sankčním seznamu
                                if (!sanction || sanction?.data?.IsValid !== true) {
                                    setErrorSanctionRegion(true);
                                    setSummaryErrors([
                                        {
                                            code: '',
                                            message: t('pages.personalContactInformation.errors.isFromSanctionRegion'),
                                        },
                                    ]);
                                    setContinueDisabled(true);
                                }

                                // není v sankčním seznamu
                                if (sanction && sanction?.data?.IsValid === true) {
                                    // validate finish
                                    callValidateFinish({ data: result, token })
                                        .then((response) => response.data)
                                        .then(async (result: ValidationResultType) => {
                                            if (result.Passed) {
                                                await continueNextStep(newModel);
                                            }
                                            if (!result.Passed) {
                                                setSummaryErrors(parseValidationErrors(result));
                                                // if (isDevelopment()) {
                                                //     setTimeout(() => continueNextStep(newModel), 5000);
                                                // }
                                            }
                                        })
                                        .finally(() => setLoading(false));
                                }
                            })
                            .catch(() => setLoading(false));
                    }
                    ctx.showPopup(null);
                }
            }}
        >
            {data ? (
                <LimitedAccess minStep={STEP_CODE.BENEFICIARIES}>
                    <Head heading1={t('pages.summary.title')} heading2={t('pages.summary.subtitle')} />

                    {summaryErrors && (
                        <GlobalError
                            errors={summaryErrors}
                            withoutTitle={errorSanctionRegion && summaryErrors.length === 1}
                            data-test="summaryError"
                        />
                    )}

                    <div className="flex flex-col gap-y-8 md:gap-y-16" data-test="summary">
                        <Card data-test="summaryBasicInfoCard">
                            <Title
                                tag="h3"
                                size="md"
                                fontWeight="medium"
                                className="mb-12"
                                data-test="summaryBasicInfoTitle"
                            >
                                {t('pages.summary.basicInfoTitle')}
                            </Title>
                            <TableSummaryInformation />
                        </Card>

                        <Card data-test="coverageTableCard">
                            <Title
                                tag="h3"
                                size="md"
                                fontWeight="medium"
                                className="mb-12"
                                data-test="coverageTableTitle"
                            >
                                {t('pages.summary.coverageTitle')}
                            </Title>

                            <TableCoverage />

                            <Title
                                tag="strong"
                                size="lg"
                                fontWeight="medium"
                                className="ml-auto mr-0 flex flex-col text-right font-medium md:flex-row mdmax:text-sm"
                                data-test="totalMonthlyPremium"
                            >
                                <span className="ml-auto inline-flex text-md mdmax:mb-4">
                                    {t('common.totalMonthlyPremium')}
                                </span>
                                <div
                                    className="ml-12 text-lg font-bold text-purple mdmax:ml-auto"
                                    data-test="totalMonthlyPremiumValue"
                                >{`${data?.InsuredPersons[0]?.SuggestedInsurance.PremiumTotal.Monthly.toLocaleString(
                                    'pl'
                                )} ${PL_CURRENCY}`}</div>
                            </Title>
                        </Card>

                        <Card data-test="agreementCard">
                            <CustomFormik passedRef={formikRef} initialValues={{ agreement: false }}>
                                <Field
                                    component={Switch}
                                    name="agreement"
                                    label={`${t('pages.summary.switchText')}:`}
                                    data-test="agreement"
                                />
                            </CustomFormik>
                            <UnorderedList
                                className="mt-8"
                                data={t('pages.summary.list', {
                                    returnObjects: true,
                                    interpolation: {
                                        escapeValue: false,
                                    },
                                    agentName: `${agentPersonalData?.FirstName || ''} ${
                                        agentPersonalData?.LastName || ''
                                    }`,
                                    agentRpu: agentPersonalData?.RpuNo,
                                    agencyName: agentPersonalData?.AgencyShortName,
                                    agencyRpu: agentPersonalData?.AgencyRpuNo,
                                })}
                                data-test="agreementTexts"
                            />
                        </Card>
                    </div>
                </LimitedAccess>
            ) : (
                <Loader />
            )}
        </Layout>
    );
};

export default Summary;
