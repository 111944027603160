import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';
import { AppContext } from '../../components/Context';
import { getOwner } from '../../utils';

interface ContactFormSentType {
    email?: string;
    phoneNumberPrefix?: string;
    phoneNumber?: string;
}

interface ContactContextType {
    contactFormSent: ContactFormSentType | null;
    setContactFormSent: Dispatch<SetStateAction<ContactFormSentType | null>>;
    isContactFormSended: (values: any) => boolean;
    resendEnabled: boolean;
    setResendEnabled: (value: SetStateAction<boolean>) => void;
    sendConsent: boolean;
    setSendConsent: (value: SetStateAction<boolean>) => void;
}

interface ContextProps {
    children: ReactNode;
}

export const ContactContext = createContext<ContactContextType>({
    contactFormSent: null,
    setContactFormSent: (value: SetStateAction<ContactFormSentType | null>) => {},
    isContactFormSended: () => false,
    resendEnabled: false,
    setResendEnabled: (value: SetStateAction<boolean>) => {},
    sendConsent: false,
    setSendConsent: (value: SetStateAction<boolean>) => {},
});

export const Context = ({ children }: ContextProps) => {
    const ctx = useContext(AppContext);
    const data = ctx.currentModel;
    const owner = data ? getOwner(data) : null;

    const contactFormSendedFromData =
        owner && data?.Participants?.find((p) => p.ExternalId === owner.ParticipantExternalId)?.Contact;

    const [sendConsent, setSendConsent] = useState(true);
    const [resendEnabled, setResendEnabled] = useState(true);
    const [contactFormSent, setContactFormSent] = useState<ContactFormSentType | null>(
        contactFormSendedFromData && contactFormSendedFromData?.Email !== '' && contactFormSendedFromData?.Phone !== ''
            ? {
                  email: contactFormSendedFromData?.Email,
                  phoneNumber: contactFormSendedFromData?.Phone,
                  phoneNumberPrefix: contactFormSendedFromData?.PhonePrefix?.Prefix,
              }
            : null
    );

    const isContactFormSended = (values: ContactFormSentType | null): boolean => {
        return (
            values?.email === contactFormSent?.email &&
            values?.phoneNumberPrefix === contactFormSent?.phoneNumberPrefix &&
            values?.phoneNumber === contactFormSent?.phoneNumber
        );
    };

    return (
        <ContactContext.Provider
            value={{
                contactFormSent,
                setContactFormSent,
                isContactFormSended,
                resendEnabled,
                setResendEnabled,
                sendConsent,
                setSendConsent,
            }}
        >
            {children}
        </ContactContext.Provider>
    );
};
