import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { Head } from '../../components/Head';
import { Card } from '../../components/common/Card';
import { Title } from '../../components/common/Title';
import { List } from '../../components/common/List';
import { AppContext } from '../../components/Context';
import { getOwner, isMojeIdEnabled } from '../../utils';
import { IdentityConfirmatorType } from '../../types/enums/IdentityConfirmatorType';
import { ROUTE } from '../../constants';
import { Layout } from '../../components/Layout';
import { Button } from '../../components/common/Button';
import { callMojeIdGenerateCode } from '../../apis/moje-id';
import { callGetOnlinePolicyMetadataByCode, CallGetOnlinePolicyMetadataByCodeResponse } from '../../apis/support';

export const VerifyIdentity = () => {
    const { t } = useTranslation();
    const { showPopup, ...ctx } = useContext(AppContext);
    const data = ctx.currentModel;
    const policyOwner = data ? getOwner(data) : null;
    const navigate = useNavigate();
    const { identityId } = useParams();
    const [processing, setProcessing] = useState(false);
    const [metadata, setMetadata] = useState<CallGetOnlinePolicyMetadataByCodeResponse | null>(null);

    const sideTranslation =
        policyOwner?.IdentityConfirmation?.IdentityConfirmatorType?.toString() ===
        IdentityConfirmatorType.Agent.toString()
            ? 'agentSide'
            : 'clientSide';

    const pageTranslation: any = t(`pages.clientVerifyIdentity.${sideTranslation}`, { returnObjects: true });

    const handleClickContinue = () => {
        navigate(ROUTE.CLIENT_VERIFY_SNAP.replace(':identityId', identityId || ''));
    };

    const handleClickSendSmsForMojeId = () => {
        if (metadata) {
            setProcessing(true);
            callMojeIdGenerateCode({ data: { onlinePolicyExternalId: metadata.OnlinePolicyExternalId } })
                .then(() => showPopup('success-sms-sended'))
                .finally(() => setProcessing(false));
        }
    };

    useEffect(() => {
        if (identityId) {
            // kontrola souhlasů
            callGetOnlinePolicyMetadataByCode({
                data: {
                    verificationCode: identityId,
                },
            })
                .then((response) => response.data)
                .then(setMetadata)
                .catch((err) => console.error(err));
        }
        // eslint-disable-next-line
    }, [identityId]);

    return (
        <Layout
            hideNavigation
            hideProgressBar
            hideSaveButton
            hideUserMenu
            continueDisabled={processing || !metadata}
            footerContent={
                isMojeIdEnabled() ? (
                    <Button isOutlined isDisabled={processing || !metadata} onClick={handleClickSendSmsForMojeId}>
                        {t('pages.clientVerifyIdentity.sendSmsForMojeId')}
                    </Button>
                ) : null
            }
            footerClassName={isMojeIdEnabled() ? 'flex-col md:flex-row gap-y-3' : undefined}
            continueCallback={handleClickContinue}
        >
            <Head
                heading1={t(`pages.clientVerifyIdentity.${sideTranslation}.title`)}
                heading2={t(`pages.clientVerifyIdentity.${sideTranslation}.subtitle`)}
            />

            <Card data-test="clientVerifyIdentityCard">
                <Title
                    tag="h2"
                    size="md"
                    fontWeight="medium"
                    className="mb-8 inline-flex items-center justify-center gap-x-6"
                    data-test="clientVerifyIdentityTitle"
                >
                    {t(`pages.clientVerifyIdentity.${sideTranslation}.card.title`)}
                </Title>

                <List type="ordered" items={pageTranslation.card.list} data-test="clientVerifyIdentityTexts" />
            </Card>
        </Layout>
    );
};
