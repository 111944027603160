import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FooterMobileMenu from '../../components/FooterMobileMenu';
import FooterMobileMenuItem from '../../components/FooterMobileMenuItem';
import { useAppNavigate } from '../../utils/hooks';
import { Button } from '../../components/common/Button';
import { ROUTE } from '../../constants';
import { callGenerateMojeIdCode } from '../../apis/documents';
import { AppContext } from '../../components/Context';

interface FooterContentProps {
    onSetContinueDisabled: (value: boolean) => void;
}

const FooterContent = ({ onSetContinueDisabled }: FooterContentProps) => {
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(false);
    const { currentModel } = useContext(AppContext);
    const { navigateTo } = useAppNavigate();
    const token = localStorage.getItem('token') || '';

    const sendSmsAgain = () => {
        if (currentModel && token) {
            setDisabled(true);
            callGenerateMojeIdCode({
                data: { onlinePolicyExternalId: currentModel?.ExternalId },
                token,
            })
                .then((response) => response.data)
                .then(() => navigateTo(ROUTE.MOJE_ID))
                .finally(() => setDisabled(false));
        }
    };

    const selectDiferentMethod = () => {
        navigateTo(ROUTE.IDENTITY_VERIFICATION);
    };

    useEffect(() => {
        onSetContinueDisabled(disabled);
    }, [disabled, onSetContinueDisabled]);

    return (
        <>
            <div className="flex grow flex-row flex-nowrap justify-end gap-x-4 mdmax:hidden">
                <Button isOutlined isDisabled={disabled} onClick={sendSmsAgain} data-test="mojeIdSendSmsAgain">
                    {t('common.sendSmsAgain')}
                </Button>
                <Button
                    isOutlined
                    isDisabled={disabled}
                    onClick={selectDiferentMethod}
                    data-test="mojeIdSendDifferentIdentification"
                >
                    {t('common.selectDifferentIdentification')}
                </Button>
            </div>

            <div className="relative ml-auto md:hidden">
                <FooterMobileMenu
                    control={
                        <Button
                            isOutlined
                            isDisabled={disabled}
                            icon="dots"
                            className="ml-auto"
                            data-test="mojeIdMoreButton"
                        >
                            {t('common.more')}
                        </Button>
                    }
                >
                    <FooterMobileMenuItem onClick={sendSmsAgain} data-test="mojeIdMoreSendSmsAgain">
                        {t('common.sendSmsAgain')}
                    </FooterMobileMenuItem>
                    <FooterMobileMenuItem
                        onClick={selectDiferentMethod}
                        data-test="mojeIdMoreSendDifferentIdentification"
                    >
                        {t('common.selectDifferentIdentification')}
                    </FooterMobileMenuItem>
                </FooterMobileMenu>
            </div>
        </>
    );
};

export default FooterContent;
