import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';
import { MojeIdGetClientDataResponse } from '../../types/model';

interface CallMojeIdGetPersistedDataProps {
    data: {
        onlinePolicyExternalId: string;
        participantExternalId: string;
        requestIdentificatorText?: string;
    };
    token: string;
}

interface CallMojeIdGetPersistedDataResponse {
    Items: Array<{
        ClientMojeIdXml: string;
        ReceivedClientPersonDtoJson: string;
        ConfirmedClientPersonDtoJson: string;
    }>;
}

export const callMojeIdGetPersistedData = ({
    data,
    token,
}: CallMojeIdGetPersistedDataProps): Promise<AxiosResponse<CallMojeIdGetPersistedDataResponse, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}moje-id/get-persisted-moje-id-data`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });

export const parseMojeIdPersistedClientDataResponse = (
    response: CallMojeIdGetPersistedDataResponse
): MojeIdGetClientDataResponse | null => {
    const item = (response?.Items || []).find((i) => i.ConfirmedClientPersonDtoJson);
    if (item) {
        return JSON.parse(item.ConfirmedClientPersonDtoJson);
    }
    return null;
};
