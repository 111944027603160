import axios, { AxiosResponse } from 'axios';
import { getBaseUrl } from '../../utils';
import { API_PATH } from '../../constants';

interface CallGenerateMojeIdCodeProps {
    data: {
        onlinePolicyExternalId: string;
    };
    token: string;
}

export const callGenerateMojeIdCode = ({
    data,
    token,
}: CallGenerateMojeIdCodeProps): Promise<AxiosResponse<any, any>> =>
    axios({
        method: 'post',
        url: `${getBaseUrl()}${API_PATH}moje-id/generate-moje-id-code`,
        data,
        headers: {
            'X-Simplea-Auth-Token': token,
        },
    });
