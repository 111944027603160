import { IdentityConfirmationSourceType } from '../../types/enums/IdentityConfirmationSourceType';
import { useContextHelpers } from '../../utils/hooks';

const useIdentityVerificationHelpers = () => {
    const { isF2F, isRemote, isPep } = useContextHelpers();

    const isDeclarationRequired = isF2F;
    const isPersonalDataRequired = (v: any) => isRemote || (isF2F && v?.declaration && isPep);
    const isPersonalDataSimpleRequired = (v: any) => v?.declaration && isF2F && !isPep;
    const isConfirmatorTypeRequired = (v: any) =>
        isPersonalDataRequired(v) && v?.personalData === IdentityConfirmationSourceType.Photocopy.toString();

    return {
        isDeclarationRequired,
        isPersonalDataRequired,
        isPersonalDataSimpleRequired,
        isConfirmatorTypeRequired,
    };
};

export default useIdentityVerificationHelpers;
